  .hero-display {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column; 
  }

  .address-input {
    flex: 0 0 auto;
    width: 40%;
  }