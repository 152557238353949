.App {
  min-height: 100vh;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
}

.homepage-div {
  min-height: 50vh;
}

.header {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button {
  font-family: Poppins;
  font-size: 16px;
  position: relative; 
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAECAYAAACHtL/sAAAAAXNSR0IArs4c6QAAADVJREFUGJVjdFVk+c9AAWD8d6sMYcDjNwwMDAwMr+6eYWBgYGAQUzZB4WM1gGIX/DjeQ5EBAEiDENCG60ZlAAAAAElFTkSuQmCC), url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAECAYAAACKqs+rAAAAAXNSR0IArs4c6QAAAD5JREFUGJVj/HG85z8DDQCjqyILbQz+tzfpP4OsCAPD4zcMDAwMDK/unoFLiimbMDDIimDXCVWPDJD10szFAL7xEtASrI1pAAAAAElFTkSuQmCC);
  background-position: center top, center bottom;
  background-repeat: repeat-x;
  background-size: 48px 12px;
  image-rendering: pixelated; 
  /* left: -50%;  */
  z-index: 2000;
  background-color: rgb(255, 224, 183);
}

@media (min-width: 576px) {
  .button {
    margin-right: 10vh;
  }
}

.group {
  display: block;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAAXNSR0IArs4c6QAAAMJJREFUGJV1zr8LAWEcx/H3c546g9FytqdOKTebLY/RbGSX1V+gWE02pPwDRn+DzXATyo9FOYOUXB4Dd47ymb7Dq75vwXtaScOfiQiM2wVW2ysAm+MNgEw6RbVZe4HDwDPhvGG0kkYrafYDL761kkYCLJZn+r0J83UotJLGyZcAn2GnRVblsJK/oy7hBg+ArMphO0VkBEbtAk6+hHCDR6U8s2bTLrZTBPiges8HfIAvEKNdEALEDUkAvJpOlzvJht89AbDgP6EwpvW5AAAAAElFTkSuQmCC"), url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAAXNSR0IArs4c6QAAAMJJREFUGJV1jj8LAXEYxz+nX12ZDJQblCMTGe2GU1LegixGszegzjuQxaDuDdgYrFJMNyjJ4t/EDQbl9DOcn47y1FPP8/Tp+3w0yxSSPzXd+RqAdh4U5dz1uN2fAKQTOgCZVJRGbx2Alimk6kO/8Jn9aVMe33tk7NgMu20AjFzp86baGrF0PQCEbuSJvx20bHD89RQAupFn7NhUyh0mszpyG+O0WbBSSYoOg6ratSQAkXCsApXj/up/J4VB5Xi5PQB4AdSpSPMicMb7AAAAAElFTkSuQmCC"), url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAAXNSR0IArs4c6QAAAG5JREFUGJVjdFVk+e+gx89w4NJHhs3LOhjYJbUZ0AEThggWQGVFQjysDAwMDAxv7j/DqoiRgYGBwVWR5X+QlQjDumNvGOa35jGIKEphKoIpzPOWYJi09QV2k5AV7pwTB+HIijAwPH6DqQimEF0MAFlKGq+e08LiAAAAAElFTkSuQmCC"), url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAAXNSR0IArs4c6QAAAINJREFUGJVj/P9g+38GNPDz+VUG36gKBgc9foYDlz4yMKErwAZorOjN/WcMDAwMDEI8rAwMDAwMLD+fX8VQkFg9iSHISoRh3bE3DLvv/2FkdFVkwfBdnrcEw6StLxh23//DyMDAwMD4b2/S/1d3z6Aoim2/BlfAwMDAgNUkZAUMDAwMAO1ULrmQ4pJuAAAAAElFTkSuQmCC");
  background-position: left top, right top, left bottom, right bottom;
  background-repeat: no-repeat;
  background-size: 27px 27px;
  width: calc(100% + 24px);
  height: calc(100%);
  z-index: 2001;
}

.item {
  background-size: 27px 27px;
  position: relative;
  text-align: center;
  color: rgb(0, 0, 0);
  font-family: Lora, serif;
  font-weight: bold;
  font-size: 20px;
  z-index: 2003;
  padding: 12px 24px;
}

.down-arrow {
  width: 24px; 
  height: 21px; 
  z-index: 2002;
  position: absolute;
  flex-direction: row;
  image-rendering: pixelated; 
}

.link {
  text-decoration: none;
  color: #000;
  cursor: pointer;
  pointer-events: revert !important;
}